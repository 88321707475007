<template>
  <div class="fluid" id="c_locations">
    <validation-observer ref="simpleRules">
      <b-card style="">
        <p class="head-text">Client wise Location & Break Configuration</p>
        <div class="row">
          <div v-if="logedInUser.roles[0].name === 'superadmin'" class="col-md-6">
            <label for="" class="label-font">Company</label>
            <b-form-group class="rounded">
              <v-select
                v-model="selectedCompany"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="getCustomers()"
                label="name"
                :options="companies"
                placeholder="Select Company to filter Clients"
              />
            </b-form-group>
          </div>
          <div v-if="logedInUser.roles[0].name !== 'customer'" class="col-md-6">
            <label for="" class="label-font"
              >Client <span class="text-danger">*</span></label
            >
            <b-form-group class="rounded">
              <v-select
                v-model="selectedCustomer"
                @input="getClientLocations"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="clients"
                placeholder="Select Client for Location Configuration"
              />
            </b-form-group>
          </div>
        </div>
        <div>
          <form>
            <div v-for="(c_location, index) in c_locations" :key="index">
              <fieldset class="fieldset">
                <legend>{{ c_location.name }} Location</legend>
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="" class="label-font"
                          >Location Name
                          <span class="text-danger">*</span></label
                        >
                        <b-form-group class="rounded">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="`Location-${index + 1} Name`"
                          >
                            <v-select
                              v-model="c_location.location"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              label="name"
                              :options="locations"
                              placeholder="Select Location Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div
                      v-for="(brk, idx) in c_location.breaks"
                      :key="idx"
                      class="row d-flex align-items-center"
                    >
                      <div class="col-md-10">
                        <div class="row">
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break Name
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Location-${index + 1} Break-${
                                  idx + 1
                                } Name`"
                              >
                                <b-form-input
                                  v-model="brk.break_name"
                                  placeholder="Enter Break Name"
                                  class="data-text"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break Type
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Shift-${index + 1} Break-${
                                  idx + 1
                                } Type`"
                              >
                                <v-select
                                  v-model="brk.type"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="breakTypes"
                                  placeholder="Select Break Type"
                                  label="text"
                                  return-object
                                  @input="handleBreakType(brk)"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break Duration (Minutes)
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Shift-${index + 1} Break-${
                                  idx + 1
                                } Duration`"
                              >
                                <v-select
                                  v-model="brk.break_min"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="minutes"
                                  placeholder="Select Break Duration in Minutes"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <button
                          @click.prevent="removeBreak(index, idx, brk)"
                          v-if="c_location.breaks.length > 1"
                          type="button"
                          class="btn btn-danger btn-sm"
                        >
                          X
                        </button>
                        <button
                          @click.prevent="addNewBreak(index)"
                          v-if="c_location.breaks.length - 1 === idx"
                          type="button"
                          class="btn btn-success btn-sm ml-1"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 d-flex justify-content-end align-items-center">
                    <button
                      @click.prevent="removeLocation(index, c_location)"
                      v-if="c_locations.length > 1"
                      type="button"
                      class="btn btn-danger btn-sm"
                    >
                      X
                    </button>
                    <button
                      @click.prevent="addNewLocation"
                      v-if="c_locations.length - 1 === index"
                      type="button"
                      class="btn btn-success btn-sm ml-1"
                    >
                      +
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <b-button v-if="$can('location break configuration', 'Client')" @click.prevent="saveData" class="btn btn-success"
                  >Save</b-button
                >
              </div>
            </div>
          </form>
        </div>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BFormTimepicker,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { toast } from "@/utils/toast";
import moment from "moment";
import { email, required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getLogedInUser } from "@/utils/auth";
export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormTimepicker,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      required,
      homeLink: false,
      c_locations: [
        {
          location_id: "",
          breaks: [
            {
              break_name: "",
              type: null,
              status: null,
              break_min: "",
            },
          ],
        },
      ],
      clients: [],
      companies: [],
      locations: [],
      selectedCompany: null,
      selectedCustomer: null,
      selectedLocation: null,
      logedInUser: JSON.parse(getLogedInUser()),
      minutes: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      breakTypes: [
        {
          value: 1,
          text: "Paid",
        },
        {
          value: 0,
          text: "Unpaid",
        }
      ],
    };
  },
  created() {
    if (this.logedInUser.roles[0].name === "superadmin") {
      this.getCompanies();
    }
    if (this.logedInUser.roles[0].name === "admin") {
      this.selectedCompany = this.logedInUser;
      this.getCustomers();
    }
    if (this.logedInUser.roles[0].name === "customer") {
      this.companies = this.logedInUser.customer_to_company;
      this.selectedCustomer = this.logedInUser;
    }
    
    this.getLocations();
  },
  methods: {
    getLocations() {
      this.$store.dispatch("location/getLocationsDropdown").then((response) => {
        this.locations = response.data;
      });
    },
    handleBreakType(brk) {
      if (brk.type) {
        brk.status = brk.type.value;
      }
    },
    removeLocation(index, c_location) {
      if (this.c_locations.length > 1) {
        if (c_location.id) {
          // show delete confirmation modal
          this.$bvModal
            .msgBoxConfirm("Are you sure you want to delete this location?", {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.deleteLocationBreak(c_location.id, null);
                this.c_locations.splice(index, 1);
              }
            })
            .catch((err) => {
              // An error occurred
            });
        } else {
          this.c_locations.splice(index, 1);
        }
      }
    },
    addNewLocation() {
      this.c_locations.push({
        location_id: "",
        breaks: [
          {
            break_name: "",
            type: null,
            status: null,
            break_min: "",
          },
        ],
      });
    },
    addNewBreak(index) {
      this.c_locations[index].breaks.push({
        break_name: "",
        type: null,
        status: null,
        break_min: "",
      });
    },
    removeBreak(index, idx, brk) {
      if (this.c_locations[index].breaks.length > 1) {
        if (brk.break_id) {
          // show delete confirmation modal
          this.$bvModal
            .msgBoxConfirm("Are you sure you want to delete this break?", {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.deleteLocationBreak(null, brk.break_id);
                this.c_locations[index].breaks.splice(idx, 1);
              }
            })
            .catch((err) => {
              // An error occurred
            });
        } else {
          this.c_locations[index].breaks.splice(idx, 1);
        }
      }
    },
    deleteLocationBreak(id, break_id) {
      const data = {
        id: id,
        break_id: break_id,
      };
      this.$store
        .dispatch("customer/deleteLocation", data)
        .then((response) => {
          if (response.status_code === 200) {
            if (data.break_id) {
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Break Delete Successfully"
              );
            } else {
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Location and it's breaks are deleted successfully"
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    timeFormat(time) {
      return moment(time).format("hh:mm A");
    },
    async getClientLocations() {
      await this.$store
        .dispatch("location/getClientLocations", this.selectedCustomer?.id)
        .then((response) => {
          this.c_locations = response.data;
          if (this.c_locations.length === 0) {
            this.c_locations.push({
              location_id: "",
              breaks: [
                {
                  break_name: "",
                  type: null,
                  status: null,
                  break_min: "",
                },
              ],
            });
          } else {
            this.c_locations.forEach((location) => {
              if (location.breaks.length > 0) {
                location.breaks.forEach((brk) => {
                  brk.type = this.breakTypes.find(t => t.value === brk.status)
                });
              } else {
                location.breaks.push({
                  break_name: "",
                  type: null,
                  status: null,
                  break_min: "",
                });
              }
              
            });
          }
        });
    },
    async getCompanies() {
      await this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then((response) => {
          this.companies = response.data;
        });
    },
    async getCustomers() {
      const id = this.selectedCompany?.id;
      let query = {
        user_id: id,
      };
      await this.$store
        .dispatch("customer/getCustomersForDropdown", query)
        .then((response) => {
          this.clients = response.data;
        });
    },

    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.c_location.id) {
        this.updateLocation();
      }
    },
    saveData() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.selectedCustomer) {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              "Please Select Client"
            );
            return;
          } else {
            let locations = [];
            this.c_locations.forEach((loc) => {
              let location = {
                location_id: loc.location.id,
                id: loc.id,
                breaks: loc.breaks,
              };
              locations.push(location);
            });
            const data = {
              customer_id: this.selectedCustomer.id,
              locations: locations,
            };
            this.createLocation(data);
          }
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all required fields"
          );
        }
      });
    },
    createLocation(data) {
      this.$store
        .dispatch("customer/createLocation", data)
        .then((response) => {
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Location Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}
.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}
.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}

.fieldset {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  padding: 0.35rem 0.625rem 0.75rem !important;
}
.head-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #515b60;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
